import React, { useEffect } from 'react';
import './imgVisualizer.css';

function ImgVisualizer(params) {

    return (
        <div>

        </div>
    );
}

export default ImgVisualizer;