import React from 'react';
import './cpSpliter.css';

const CpSpliter = () => {
    

  return (    
            <div className="spliter">
            </div>
  );
}

export default CpSpliter;